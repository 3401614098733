<template>
  <div class="station-game-play">
    <div class="d-flex align-items-center">
      <div class="cha-circle circle cha-circle-s mr-3 col-auto p-0">
        <img :alt="user.name" class="cover-fit" v-bind:src="showAvatar(character.picture)" />
      </div>
      <div class="col p-0">
        <input class="stage-input w-100" placeholder="輸入留言.." type="text" v-model="message" />
      </div>
      <a @click="sendMsg" class="mr-2 px-2 col-auto text-white cursor-default" role="button">送出</a>
    </div>
  </div>
</template>
<script>
import UserFromVuex from "../../Mixins/UserFromVuex";
import Avatar from "../../Mixins/Avatar";
import { mapActions } from "vuex";

export default {
  name: "talkingInput",
  mixins: [UserFromVuex, Avatar],
  props: {
    label: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      message: ""
    };
  },
  methods: {
    ...mapActions("Chat", ["sendTalk"]),
    sendMsg() {
      // let newMsg = {
      //   character: "小希",
      //   picture: "",
      //   class: "talk-me",
      //   content: this.message
      // };
      // this.talkingList.push(newMsg);

      this.sendTalk({
        label: this.label,
        content: this.message
      });
      this.message = "";
    }
  }
};
</script>
<style lang="scss" scoped>
.station-game-play {
  padding: 30px;
}

.station-game-play {
  padding: 20px;
}
</style>
