<template>
  <div class="position-relative full-page ghost-bg">
    <logoutBtn position="upper-left"></logoutBtn>
    <fullpageInfo></fullpageInfo>
    <div class="panel-back"></div>
    <rule :rules="rules" :page="page"></rule>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import rule from "@/views/part/rule";
import logoutBtn from "@/views/part/logoutBtn";

export default {
  name: "taipeiStationRule",
  props: ["page"],
  data() {
    return {
      rules: [
        {
          title: "<h1 class='text-white text-center'>來抓我呀！</h1>",
          content:
            "<p>這群人真是一點地理概念都沒有耶！台北車站這麼大，還有100個名字很像的不同出口！超容易迷路的！約在台北車站集合時，永遠都會有人找不到集合地點，每次都覺得來這裡找人跟玩鬼抓人一樣……<br/>" +
            "沒辦法了，得靠最聰明優秀的你，把迷路的小夥伴們一個一個找回來囉！動作快，別讓小桃等太久！</p>"
        },
        {
          title: "<h3>躲藏時間</h3>",
          content:
            "<p>遊戲開始後會有三分鐘的躲藏時間，在躲藏時間的三分鐘內，請各位玩家在<strong>台北車站室內一樓中央廣場</strong>的範圍內，找地方躲好<br/></p><div class='text-center'><img src='/img/stationRule1.jpg'></div>"
        },
        {
          title: "<h3>修但幾累</h3>",
          content:
            "<p>極不建議躲在男女廁所中，如果真的不幸發生了，請大家給予最嚴厲的譴責<br/><br/><br/><br/></p><div class='text-center'><img src='/img/stationRule2-1.jpg'></div>"
        },
        {
          title: "<h3>Step2. 抓人時間</h3>",
          content:
            "<p><strong>遊戲採用互抓的機制</strong>，躲藏時間結束後，每個玩家手機螢幕上都會顯示自己的獨家指令，例如下圖裡的左方玩家的獨家指令是『⇩、⇦、⇩、⇧』，他的手機同時也會發出『下、左、下、上』的音效聲，而下圖裡的<strong>右方玩家在自己手機輸入『⇩、⇦、⇩、⇧』的指令後，就可以抓到左方玩家囉！</strong></p><p>為了遊戲公平性，每隔20秒，所有玩家的指令都會自動刷新。請神不知鬼不覺地偷看、偷聽其他玩家手機上的指令！若不幸被發現了，請硬拼硬、尬手速吧！</p><div class='text-center'><img src='/img/stationRule2.jpg'></div>"
        },
        {
          title: "<h3>修但幾累</h3>",
          content:
            "<p>請在出發躲藏前，檢查所有人手機將音量調至最大、畫面調至最亮，然後打勾勾約法三章，如果有機掰郎不遵守，只好……現場直接圍毆打爆！<br/><br/></p><div pt-0 class='text-center'><img src='/img/stationRule3.jpg'></div>"
        },
        {
          title: "<h3>Step3. 獲勝判定</h3>",
          content:
            "<p>遊戲時間到，或是全場只剩下一個玩家的時候，遊戲就結束囉！<strong>活到最後且抓到最多人</strong>的玩家獲勝！若是平手情況的話，就交給上天隨機決定囉！運氣也是戰場上重要的一環呢！</p><p>已經被抓到的人雖然不能再繼續抓人，但可以看情況扮演「小助手」或是「小住手」，看你心情囉</p><div class='text-center'></div>"
        },
        {
          component: "ghostRuleVideo"
        },
        {
          component: "ghostRuleEnd"
        }
      ]
    };
  },
  components: {
    fullpageInfo,
    rule,
    logoutBtn
  }
};
</script>

<style lang="scss">
.ghost-bg {
  .rule-panel {
    h1 {
      padding-top: 30px;
      font-size: 45px;
    }

    p {
      color: #fff;
      font-size: 15px;
    }
    img {
      max-width: 220px;
    }
    strong {
      color: #4285f4;
    }
    span {
      color: #c64c72;
    }
  }
}
</style>
