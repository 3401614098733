<template>
  <div class="full-page overflow-auto" ref="container">
    <FavorContainer
      v-if="flag('stage_7_photo_clear') && flag('stage_7_photo_clear_favor') == false"
      @close="photoClearbonusDone"
      :stage="stage"
      :step="3"
      :stageFinished="true"
    ></FavorContainer>
    <div class="position-relative stage-content" ref="stage">
      <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
      <moreContent @click="showProgressUpdate = false" v-if="showProgressUpdate"></moreContent>
      <img src="/img/nokia.jpg" class="img-fluid" alt="" style="margin-bottom: 50px;" />

      <checkPosition
        v-if="!flag('stage_7_check1stPlace')"
        :location="check1stPosition"
        :local="[25.047207, 121.516971]"
      ></checkPosition>

      <template v-if="flag('stage_7_check1stPlace')">
        <igStoryGoGame :video="phase1IgStory" @goGame="goGame"></igStoryGoGame>
        <stageMessageNoInput :data="phase1IgStoryMsg"></stageMessageNoInput>
      </template>
      <div v-if="flag('stage_7_ghostRule')" class="d-flex position-relative justify-content-center py-5">
        <a class="d-flex flex-column align-items-center justify-content-center" @click="goGhostRule">
          <div class="text-center">
            <img class="" src="/img/icon-finish.svg" alt="" />
            <div class="mt-2 icon-intro">繼續關卡</div>
          </div>
        </a>
      </div>
      <template v-if="flag('stage_7_ghostFinish')">
        <winnerCharacter :winnerCharacter="flag('stage_7_ghostWinner')" :winnerTalk="winnerTalk"></winnerCharacter>
        <photoMission
          :photo="photoMission"
          :stage="stage"
          :returnToHomeMap="false"
          @final="showFinalButton"
          @next="next"
          @showStageFinishedFavor="showStageFinishedFavor"
        >
          <stageMessageNoInput :data="photoMissionMsg"></stageMessageNoInput>
        </photoMission>
      </template>
      <a
        v-if="showFinal"
        @click="final"
        class="position-fixed page-btn d-flex justify-content-center align-items-center"
      >
        結束遊戲<br/>觀賞結局
      </a>
    </div>
    <mainMenu></mainMenu>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import mainMenu from "@/views/part/mainMenu";
import stageHeader from "@/views/part/stageHeader";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
import photoMission from "@/views/part/photoMission";
import igStoryGoGame from "@/views/part/igStoryGoGame";
import winnerCharacter from "@/views/part/winnerCharacter";
import moreContent from "@/views/part/moreContent";
import checkPosition from "@/views/part/checkPosition";

import { mapGetters } from "vuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import getDistance from "../Mixins/getDistance";
import { sendMessage } from "../utils/WebSocket";
import FavorContainer from "./favor/FavorContainer";
import ModalMixin from "@/Mixins/ModalMixin";
import ShowUpdate from "@/Mixins/ShowUpdateMixin";

export default {
  name: "taipeiStation",
  mixins: [
    Avatar,
    CharacterFromVuex,
    UserFromVuex,
    WebSocketMixin,
    TeamFromVuex,
    StageFromMixin,
    ModalMixin,
    getDistance,
    ShowUpdate
  ],
  components: {
    mainMenu,
    stageHeader,
    checkPosition,
    igStoryGoGame,
    stageMessageNoInput,
    photoMission,
    winnerCharacter,
    moreContent,
    FavorContainer
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    }
  },
  data() {
    return {
      showFinal:false,
      showProgressUpdate: false,
      showProgressUpdateFlags: ["stage_7_check1stPlace", "stage_7_ghostFinish"],
      stage: {
        stage_id: 7
      },
      siteCharacter: {
        name: "小彥"
      },
      stageLocation: {
        time: "升大學暑假",
        location: "台北車站"
      },
      check1stPosition: {
        nextPhase: "stage_7_check1stPlace",
        position: [25.047207, 121.516971],
        distance: 70
      },
      phase1IgStory: {
        src: "S07",
        poster: "mc/12.jpg",
        autoplay: false,
        stageFlag: "stage_7_goGhost"
      },
      phase1IgStoryMsg: [
        {
          name: "小彥",
          content:
            "我想要在大學前的最後一個暑假，和妳還有大家一起穿上高中制服，一起從台北車站出發，拍張合照，為高中三年留下一個完美的逗號。"
        },
        {
          name: "白爛",
          content: "我先去上廁所，我快膛炸了！"
        },
        {
          name: "花輪",
          content: "幫我看下行李，我先去買點零食，等會上車吃"
        },
        {
          name: "小希",
          content: "那我跟你去買喝的"
        },
        {
          name: "胖子",
          content: "那我先去拿車票，小桃，春哥好像說她找不到集合地點，她好像在麥當勞那邊"
        },
        {
          name: "赤木",
          content: "白爛在廁所？他一定又在女廁樓梯口不知道幹嘛！我去抓他回來！"
        },
        {
          name: "小彥",
          content: "那小桃妳顧一下行李，我去找春哥！"
        },
        {
          name: "小桃",
          content: "那我在這裡等你們大家回來拍合照！"
        }
      ],
      photoMission: {
        img: "photo-station.jpg",
        flag: "stage_7_clear",
        finish: "stage_7_photo_clear",
        local: [25.04781, 121.517247],
        flyTo: [25.04781, 121.517247]
      },
      winnerTalk: "放心！有我在，絕對把大家攬牢牢",
      photoMissionMsg: [
        {
          name: "小彥",
          content:
            "你們就是我的高中生活，沒有你們就沒有那些年的美好回憶。重點是跟你們一起吃、一起玩、一起笑、一起鬧，因為我們一起，所以那些年才如此珍貴！"
        },
        {
          name: "提示",
          content:
            "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
        }
      ]
    };
  },
  mounted() {
    this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.showProgressUpdate) {
        this.showProgressUpdate = false;
      }
    },
    next() {
      this.setFlag({ key: "stage_7_photo_clear", status: true });
    },
    showFinalButton(){
      this.showFinal = true;
    },
    final() {

      if(!this.isLeader){
        this.showModal('只有隊長可以結束遊戲')
        return;
      }
      if(!this.allMemberOnline){
        this.showModal('請確定所有人都在線上')
        return;
      }

      //如果已經有人進入過scoreResult，則自己進入結尾頁finalPanel
      //第一次點擊按鈕的當下會把所有線上成員帶到scoreResult

      if(!this.flag("scoreResultEntered")){
        this.setFlag({ key: "scoreResultEntered", status: true });
        let data = {
          method: "GameOver",
          token: this.token
        };
        sendMessage(data);

        data = {
          method: "EnterStage",
          token: this.token,
          location: {
            link: "scoreResult"
          }
        };
        sendMessage(data);
      } else {
        this.$router.replace("finalPanel");
      }
    },
    goGame: function() {
      this.setFlag({ key: "stage_7_ghostRule", status: true });
      // const data = {
      //   method: "EnterStage",
      //   token: this.token,
      //   location: {
      //     link: "ghostRule"
      //   }
      // };
      // sendMessage(data);
    },
    goGhostRule() {
      if (this.flag("stage_7_ghostFinish")) {
        this.showModal('鬼抓人沒辦法再玩一次歐');
        // this.$router.replace("ghostResult");
        return;
      }
      this.$router.replace("ghostRule");
    },
    photoClearbonusDone: function() {
      this.setFlag({ key: "stage_7_photo_clear_favor", status: true });
    },
    showStageFinishedFavor() {
      this.setFlag({ key: "stage_7_photo_clear", status: true });
    }
  }
};
</script>

<style scoped></style>
