<template>
  <div class="position-relative full-page d-flex justify-content-between flex-column">
    <div class="position-absolute ghost-game-info">
      <a
        v-if="start_btn"
        class="position-fixed page-btn d-flex justify-content-center align-items-center"
        @click="clickStart"
      >
        開始遊戲
      </a>
      <div v-if="gameReady" class="hide-time position-absolute d-flex justify-content-center align-items-center">
        <div>躲藏時間</div>
      </div>
      <div
        v-if="gameOn"
        class="station-game-control d-flex justify-content-between position-absolute align-items-center"
      >
        <a v-for="(dir, index) in direction" :key="index.toString() + dir.toLocaleString()">
          <img :class="directionToImage(dir)" alt="" src="/img/icon-arrow.svg" />
        </a>
      </div>
      <div v-if="gameOn || gameReady" class="position-absolute ghost-leave-alert">
        <img class="speaker" src="img/icons/speaker.svg" alt="" />
        <span>請勿離開本遊戲畫面</span>
        <br />
        <span>
          否則將造成遊戲斷線，無法繼續進行
        </span>
      </div>
      <div
        v-if="whoCaughtMe"
        class="station-game-got position-absolute d-flex justify-content-center align-items-center"
      >
        <div class="user-name">{{ whoCaughtMe.user.nickname }}</div>
        <div>GOT YOU!</div>
      </div>
    </div>
    <div class="station-game-play">
      <div class="d-flex justify-content-between align-items-center">
        <ul class="d-flex list-unstyled m-0 station-user-list">
          <li
            v-for="user in player_list"
            :key="user.name"
            :class="{ caught: user.caught }"
            class="mr-2 position-relative"
          >
            <div class="cha-circle circle cha-circle-s image-bigger">
              <img alt="user.name" class="cover-fit" v-bind:src="showAvatar(user.picture)" />
            </div>
            <div v-if="user.caughtCount != 0" class="position-absolute caught-count">{{ user.caughtCount }}</div>
          </li>
        </ul>
        <!-- <a>
          <svg class="btn-icon-24">
            <use class="fill-white" xlink:href="#icon-intro"></use>
          </svg>
        </a> -->
      </div>
      <div class="mb-2 text-white game-timer text-center">
        <b>{{ showTime }}</b>
      </div>
    </div>
    <talking :label="talkingLabel"></talking>
    <FavorContainer
      v-if="flag('stage_7_ghost_over') && flag('stage_7_favor') != true"
      :stage="stage"
      :step="1"
      @close="favorOver"
    ></FavorContainer>
    <div v-if="flag('stage_7_favor')" class="goStartPotision">
      <div class="content">
        <h4>請和好友們集合，以便一起觀賞遊戲結局！</h4>
        <button class="px-5 btn btn-primary btn-sm text-white" @click="leaveGhost">我知道了</button>
      </div>
    </div>
    <div class="position-relative">
      <talkingInput :label="talkingLabel" />
      <div v-if="catch_lock" class="CDTime-cover">
        <h2>
          冷卻中
          <span class="ml-2">{{ cdTimeCount }}</span>
        </h2>
      </div>
      <div :class="{ 'btn-disabled': whoCaughtMe }" class="d-flex justify-content-center padding-btn">
        <div class="d-flex align-items-center margin-right-btn">
          <a
            class="bottom-btn arrow_box "
            :class="{ 'click-active': arrowClick === 3 }"
            @click.stop.capture="inputDirection('left')"
          >
            <img class="btn-icon-24" src="img/icons/ghost_arrow_left.svg" />
          </a>
        </div>
        <div class="d-flex flex-column">
          <a
            class="bottom-btn margin-btn arrow_box"
            :class="{ 'click-active': arrowClick === 1 }"
            @click.stop.capture="inputDirection('top')"
          >
            <img class="btn-icon-24" src="img/icons/ghost_arrow_up.svg" />
          </a>
          <a
            class="bottom-btn arrow_box"
            :class="{ 'click-active': arrowClick === 2 }"
            @click.stop.capture="inputDirection('bottom')"
          >
            <img class="btn-icon-24" src="img/icons/ghost_arrow_down.svg" />
          </a>
        </div>
        <div class="d-flex align-items-center margin-left-btn">
          <a
            class="bottom-btn arrow_box"
            :class="{ 'click-active': arrowClick === 4 }"
            @click.stop.capture="inputDirection('right')"
          >
            <img class="btn-icon-24" src="img/icons/ghost_arrow_right.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import talking from "@/views/part/talking";
import Avatar from "@/Mixins/Avatar";
import StageFromMixin from "../Mixins/StageFromMixin";
import UserFromVuex from "../Mixins/UserFromVuex";
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import TalkingInput from "./part/TalkingInput";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import { mapActions, mapGetters } from "vuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import FavorContainer from "./favor/FavorContainer";
import { sendMessage } from "../utils/WebSocket";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "ghost",
  mixins: [Avatar, StageFromMixin, UserFromVuex, CharacterFromVuex, TeamFromVuex, WebSocketMixin, HowlAudio],
  components: {
    TalkingInput,
    talking,
    FavorContainer
  },
  data() {
    return {
      stage: {
        stage_id: 7
      },
      sounds: [],
      gameReady: true,
      gameOn: false,
      hideTimer: null,
      findTimer: null,
      directionInputData: [],
      start_btn: false,
      catch_lock: false,
      cdTimeCount: 3,
      cdTimetimer: null,
      arrowClick: 0,
      callDirectionLock:false
    };
  },
  computed: {
    ...mapGetters("Ghost", [
      "directions",
      "caught",
      "only_one_survived",
      "over",
      "hideTimerCount",
      "findTimerCount",
      "default_data"
    ]),
    player_list() {
      let list = this.character_map.map(d => {
        return {
          name: d.character.name,
          picture: d.character.picture,
          caught: !!this.caught.find(d2 => d2.caught == d.user_id),
          caughtCount: this.caught.filter(d2 => d2.bywho == d.user_id).length || 0
        };
      });
      return list;
    },
    gameOver() {
      return this.only_one_survived || this.findTimerCount < 1;
    },
    playerGot() {
      if (this.caught) {
        return this.caught.filter(d => d.caught == this.user.id).length > 0;
      }
      return false;
    },
    whoCaughtMe() {
      let user_id = this.caught?.find(d => d.caught == this.user.id)?.bywho;
      return this.members.find(d => d.user_id == user_id);
    },
    talkingLabel() {
      return `team:${this.team.id}:ghost`;
    },
    showTime: function() {
      let countdown = this.gameOn ? this.findTimerCount : this.hideTimerCount;
      countdown = (countdown<0)?0:countdown
      let mm = parseInt(countdown / 60);
      let ss = countdown % 60;
      if (ss < 10) ss = "0" + ss;
      return mm + ":" + ss;
    },
    direction() {
      if (this.direction_string) {
        return this.direction_string.split("");
      }
      return "";
    },
    direction_string() {
      return this.directions[this.user.id.toString()] || "";
    },
    stage_7_ghostFinish(){
      return this.flag('stage_7_ghostFinish');
    }
  },
  watch: {
    stage_7_ghostFinish(val){
      if(val){
        this.hideTimer && clearInterval(this.hideTimer);
        this.$router.replace('taipeistation')
      }
    },
    hideTimerCount(val){
      if(val>0){
        if(this.hideTimer) return;
        this.hideTimer = setInterval(this.findCountdown, 1000);
      }
    },
    findTimerCount(count){
      if(this.hideTimer) return;
      if(count>0){
        this.startFind();
      }
    },
    gameOver(gameOver, oldVal) {
      if (gameOver === oldVal) return;
      if (gameOver === false) {
        if (this.hideTimerCount < 1 && this.findTimerCount > 1) {
          //如果遊戲還沒結束 確定有在倒數
          if (!this.hideTimer) {
            this.startFind();
          }
        }
        // this.hideTimer && clearInterval(this.hideTimer);
        return;
      }
      if (gameOver) {
        // this.setGameOver();
        this.hideTimer && clearInterval(this.hideTimer);
        this.findTimer && clearInterval(this.findTimer);
      }
    },
    direction(val, oldVal) {
      if(val?.length<4) return;
      if (val?.join?.() !== oldVal?.join?.()) {
        this.callDirection();
        return;
      }
    }
  },
  created() {
    if (this.hideTimerCount > 0) {
      this.hideTimer = setInterval(this.hideCountdown, 1000);
      return;
    }
    if (this.findTimerCount > 0) {
      this.startFind();
      return;
    }
  },
  mounted() {
    window.addEventListener("focus", this.forceUpdate);
  },
  methods: {
    ...mapActions("Ghost", ["catch", "setGameOver", "setFindTimerCount", "setHideTimerCount"]),
    forceUpdate() {
      if (!this.gameOn) return;
      const data = {
        method: "GhostUpdate",
        token: this.token
      };
      sendMessage(data);
    },
    favorOver() {
      this.setFlag({
        key: "stage_7_favor",
        status: true
      });
    },
    leaveGhost() {
      this.setFlag({
        key: "stage_7_ghostFinish",
        status: true
      });
      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "taipeistation"
        }
      };
      sendMessage(data);
    },
    startFind: function() {
      this.showGameStart = true;
      this.gameReady = false;
      this.gameOn = true;
      this.start_btn = true;
      if(!this.findTimer){
        this.findTimer = setInterval(this.findCountdown, 1000);
      }
    },
    hideCountdown() {
      if (this.flag("stage_7_ghost_over")) return;
      let count = this.hideTimerCount - 1;
      this.setHideTimerCount(count);
      if (count <= 0) {
        clearInterval(this.hideTimer);
        this.startFind();
        // this.hideTimerCount = this.findTimerCount;
      }
    },
    playSound(character, direction) {
      let directionDes = "";
      switch (direction) {
        case "1":
          directionDes = "上";
          break;
        case "2":
          directionDes = "下";
          break;
        case "3":
          directionDes = "左";
          break;
        case "4":
          directionDes = "右";
          break;
      }
      this.makeDirectionSound(character, directionDes);
    },
    makeDirectionSound(character, directionDes) {
      if (!this.sounds?.[character]?.[directionDes]) {
        if (!this.sounds[character]) {
          this.sounds[character] = {};
        }
        this.sounds[character][directionDes] = this.makeAudio("/sound/" + character + directionDes + ".mp3");
      }
      this.sounds[character][directionDes].play();
    },
    findCountdown() {
      if (this.gameOver) return;
      let count = this.findTimerCount - 1;
      this.setFindTimerCount(count);
      if (count < 1) {
        clearInterval(this.findTimer);
      }
    },
    callDirection() {
      if(this.callDirectionLock) return;
      this.callDirectionLock = true;
      this.playSound(this.character.name, this.direction[0]);
      setTimeout(() => {
        this.playSound(this.character.name, this.direction[1]);
      }, 1000);
      setTimeout(() => {
        this.playSound(this.character.name, this.direction[2]);
      }, 2000);
      setTimeout(() => {
        this.playSound(this.character.name, this.direction[3]);
        this.callDirectionLock = false;
      }, 3000);
    },
    directionToImage: function(num) {
      num = parseInt(num);
      switch (num) {
        case 1:
          return "";
        case 2:
          return "arrow-down";
        case 3:
          return "arrow-left";
        case 4:
          return "arrow-right";
      }
    },
    inputDirection(direction) {
      if (this.catch_lock == true) return;
      if (this.findTimerCount <= 0) return;
      if (this.whoCaughtMe) return;

      let dir = null;
      switch (direction) {
        case "top":
          dir = 1;
          this.arrowClick = 1;
          break;
        case "bottom":
          dir = 2;
          this.arrowClick = 2;
          break;
        case "left":
          dir = 3;
          this.arrowClick = 3;
          break;
        case "right":
          dir = 4;
          this.arrowClick = 4;
          break;
      }
      setTimeout(() => {
        this.arrowClick = 0;
      }, 500);
      if (dir) {
        this.directionInputData.push(dir);
      }
      if (this.directionInputData.length >= 4) {
        let direction = this.directionInputData.slice(0, 4).join("");
        this.directionInputData.shift();

        if (direction == this.direction_string) {
          this.catch_lock = true;
          this.cdTimeCount = 3;
          this.cdTimetimer = setInterval(this.countdown, 1000);
          setTimeout(() => {
            this.catch_lock = false;
          }, 5000);
          this.directionInputData = [];
          return;
        };
        this.catch({
          direction,
          talkingLabel: this.talkingLabel,
          character: this.character
        });
        if (!this.gotPeople(direction)) {
          this.catch_lock = true;
          this.cdTimeCount = 3;
          this.cdTimetimer = setInterval(this.countdown, 1000);
          setTimeout(() => {
            this.catch_lock = false;
          }, 5000);
        }
        this.directionInputData = [];
      }
    },
    gotPeople(direction) {
      let keys = Object.keys(this.directions).filter(d => d !== this.user.id.toString());
      for (let key in keys) {
        if (this.directions[key] === direction && !this.caught.find(d => d.caught == key)) {
          return true;
        }
      }

      return false;
    },
    clickStart() {
      this.start_btn = false;
    },
    countdown() {
      this.cdTimeCount--;
      if (this.cdTimeCount == 0) {
        clearInterval(this.cdTimetimer);
      }
    }
  },
  beforeDestroy() {
    this.hideTimer && clearInterval(this.hideTimer);
    this.findTimer && clearInterval(this.findTimer);
    window.removeEventListener("focus", this.forceUpdate);
  }
};
</script>

<style lang="scss" scoped>
.full-page {
  background: url("/img/ghost-bg.jpg") center center;
  background-size: cover;
}

.caught-count {
  right: 0;
  bottom: -20px;
  color: #fff;
  font-weight: bold;
}

.hide-time {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(77, 174, 245, 0.75);
  color: #fff;
  font-weight: bold;
  font-size: 35px;
}

.talking-area {
  top: 340px;
  bottom: 160px;
  left: 0;
  right: 0;
}

.ghost-game-info {
  top: 160px;
  left: 0;
  right: 0;
  height: 110px;
}
.ghost-leave-alert {
  top: 110px;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.9);
  text-align: center;
  color: yellow;
  padding: 8px 0;
  font-weight: bold;
}

.arrow-down {
  transform: rotate(180deg);
}

.arrow-right {
  transform: rotate(90deg);
}

.arrow-left {
  transform: rotate(270deg);
}

> .station-game-play {
  padding: 30px;
}

.station-game-control {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 45px;
  background: rgba(255, 255, 255, 0.75);
}

.station-game-got {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(147, 29, 29, 0.75);
  z-index: 10;
  font-weight: bold;
  color: #fff;
  font-size: 35px;
}

.game-timer {
  margin-top: 40px;
  font-size: 37px;
}

.station-game-play {
  padding: 20px;
}

.station-user-list {
  li {
    &.caught {
      opacity: 0.5;
    }
  }
}

.btn-disabled {
  opacity: 0.6;
}

.bottom-btn {
  width: 76px;
  height: 32px;
  border: transparent;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: block;
}

.padding-btn {
  padding-bottom: 15px;
}

.margin-btn {
  margin-bottom: 12px;
}

.margin-right-btn {
  margin-right: 12px;
}

.margin-left-btn {
  margin-left: 12px;
}

.user-name {
  min-width: 159px;
  height: 27px;
  border-radius: 12px;
  background: #fff;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  font-size: 10px;
  text-align: center;
  line-height: 27px;
  padding: 0 10px;
}

.arrow_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-bigger {
  transform: scale(1.2, 1.2);
}

.CDTime-cover {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: #fff;
  }
}

.goStartPotision {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  .content {
    width: 90%;
    height: 250px;
    background-color: #fff;
    border-radius: 4px;
    padding: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    > button {
      font-size: 1.3rem;
    }
  }
}
.speaker {
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-right: 8px;
}
.click-active {
  animation-name: clickActive;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-delay: 0;
}

@keyframes clickActive {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #999;
  }
  100% {
    background-color: #fff;
  }
}
</style>
