<template>
  <div class="talking-area position-absolute">
    <div class="talking-block position-absolute" ref="scrollArea">
      <ul class="list-unstyled" ref="container" id="">
        <li class="d-flex position-relative" v-for="talk in talkingList" v-bind:class="[talk.class]" :key="talk.id">
          <div class="cha-circle circle cha-circle-s mt-2">
            <img class="cover-fit" v-bind:src="showAvatar(talk.picture)" alt="" />
          </div>
          <div>
            <div class="taking-character">{{ talk.character }} - {{ talk.nickname }}</div>
            <div class="speech-bubble">
              {{ talk.content }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import { mapActions, mapGetters } from "vuex";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import UserFromVuex from "../../Mixins/UserFromVuex";

function last(arr) {
  return arr?.[arr.length - 1];
}

export default {
  name: "talking",
  mixins: [Avatar, TeamFromVuex, UserFromVuex],
  props: {
    label: {
      required: true
    }
  },
  computed: {
    ...mapGetters("Chat", ["getTalkListByLabel", "talk_list"]),
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Team", ["members"]),
    talkingList() {
      let list = this.getTalkListByLabel(this.label);
      if (list.length == 0) return [];

      list = list.map(d => {
        let role = this.getRoleByName(d.character);
        d.picture = role?.picture;
        d.nickname = this.members.find(d2 => d2.user_id == d.user_id)?.user?.nickname;
        d.class = d.character == this.character.name ? "talk-me" : "";
        return d;
      });
      return list;
    }
  },
  watch: {
    talkingList: function(val, oldVal) {
      if (last(val)?.id == last(oldVal)?.id) return;
      // if (val.length == oldVal.length) return;
      // console.log("talk")
      this.$nextTick(() => {
        this.$refs.scrollArea.scrollTop = this.$refs.container.scrollHeight;
        // console.log(this.$refs.container.scrollTop)
      });
    }
  },
  methods: {
    ...mapActions("Chat", ["fetchTalk"])
  },
  mounted() {
    if (this.talkingList.length == 0) {
      this.fetchTalk({ label: this.label });
    }
  }
};
</script>

<style scoped lang="scss"></style>
